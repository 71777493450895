<template>
  <div v-if="isLoaded">
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!company.has_paid_subscription"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Sie müssen ein kostenpflichtes Abonement abschliessen um Optionen
        hinzuzufügen. Alle Optionen können auch direkt beim Abschliessen eines
        Abonement ausgewählt werden.
      </template>
      <router-link
        to="/adjust-subscription"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Abonement abschliessen</b-button
        >
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->
    <div v-else>
      <!--begin::Notice-->
      <b-jumbotron
        bg-variant="info"
        text-variant="white"
        border-variant="light"
      >
        <template v-slot:lead>
          Bitte beachten Sie, dass zusätzliche Optionen anteilmässig zur
          bestenden Vertragslaufzeit hinzugefügt werden. Ihr Abonement wurde am
          {{ formatDateTime(subscriptionOrder.start_date) }} abgeschlossen und
          endet am {{ formatDateTime(subscriptionOrder.end_date) }}. Daraus
          ergibt sich eine verbleibende Laufzeit von
          {{ formatInt(getRemainingSubscriptionDurationInDays) }} von total
          {{ formatInt(getSubscriptionDurationInDays) }} Tagen.
          <!--        Daraus ergibt-->
          <!--        sich die verbleibende Laufzeit von-->
          <!--        {{ getRemainingSubscriptionDuration.years() }} Jahre,-->
          <!--        {{ getRemainingSubscriptionDuration.months() }} Monate und-->
          <!--        {{ getRemainingSubscriptionDuration.days() }} Tage. Dies entspricht-->
          <!--        {{ getRemainingSubscriptionDurationInDays }} von total-->
          <!--        {{ getSubscriptionDurationInDays }} Tagen. -->
          Was einem Anteil von
          {{ getRemainingSubscriptionDurationPercentage }} % entspricht.
        </template>
        <hr class="my-4" />
        <p>
          Wenn Sie weitere Optionen innerhalb von 30 Tagen nach
          Vertragsabschluss hinzufügen, gewähren wir Ihnen den selben Rabatt wie
          auf Ihr Abonement in der Höhe von
          {{ formatFloat(subscriptionOrder.total_discount_percentage) }}%.
          Dieses Angebot ist gültig bis am
          {{ formatDateTime(subscriptionOrder.options_discount_end_date) }}.
        </p>
      </b-jumbotron>
      <!--end::Notice-->
      <!--begin::Card-->
      <div class="card card-custom">
        <!-- begin::Form-->
        <b-form class="form" v-on:submit.prevent="save">
          <!--begin::Body-->
          <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
            <div class="row">
              <div class="offset-xxl-2 col-xxl-8">
                <h4 class="mb-5 font-weight-bold text-dark">
                  Ihre Optionen im Überblick
                </h4>
                <div class="form-group">
                  <div class="border-bottom mb-10 pb-5">
                    <!--                  <SubscriptionPropertyLine-->
                    <!--                    name="Start Datum"-->
                    <!--                    :property="formatDateTime(subscriptionOrder.start_date)"-->
                    <!--                  />-->
                    <!--                  <SubscriptionPropertyLine-->
                    <!--                    name="End Datum"-->
                    <!--                    :property="formatDateTime(subscriptionOrder.end_date)"-->
                    <!--                  />-->
                    <SubscriptionPropertyLine
                      name="Firmenlogo enthalten"
                      :property="company.has_logo ? 'Ja' : 'Nein'"
                    />
                    <SubscriptionPropertyLine
                      name="Anzahl Niederlassungen"
                      :property="company.nr_of_branch_offices"
                    />
                    <SubscriptionPropertyLine
                      name="Anzahl BKP Nummern"
                      :property="company.nr_of_bkp_numbers"
                    />
                    <SubscriptionPropertyLine
                      name="Firmenportrait enthalten"
                      :property="company.has_portrait ? 'Ja' : 'Nein'"
                    />
                  </div>
                </div>

                <h4 class="mb-10 font-weight-bold text-dark">
                  Optionen hinzufügen
                </h4>
                <div class="form-group">
                  <div class="border-bottom mb-10 pb-5">
                    <div class="row" v-if="!company.has_logo">
                      <div class="col-xl-5">
                        <label
                          class="pt-4 pb-4"
                          v-b-tooltip.hover.top="'Comming soon...'"
                          >Firmenlogo hinzufügen CHF
                          {{ formatFloat(getLogoPrice) }}</label
                        >
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group">
                          <b-form-radio-group
                            class="pt-4 pb-4"
                            v-model="subscriptionOptions.has_logo_added"
                            :options="checkboxOptions"
                          ></b-form-radio-group>
                        </div>
                      </div>
                      <div class="col-xl-4 text-right">
                        <label class="pt-4 pb-4"
                          >CHF {{ formatFloat(getTotalLogoPrice) }}</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-5">
                        <label
                          class="pt-4 pb-4"
                          v-b-tooltip.hover.top="'Comming soon...'"
                          >Niederlassungen hinzufügen CHF
                          {{ formatFloat(getBranchOfficesPrice) }}</label
                        >
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group">
                          <b-form-radio-group
                            class="pt-4 pb-4"
                            v-model="
                              subscriptionOptions.has_branch_offices_added
                            "
                            :options="checkboxOptions"
                          ></b-form-radio-group>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <ValidatedNumberInput
                          v-model="
                            subscriptionOptions.nr_of_branch_offices_added
                          "
                          :v="$v.subscriptionOptions.nr_of_branch_offices_added"
                          min="0"
                          errorMessage="Der Wert muss mindestens 1 sein."
                          :helpText="'Total = ' + getTotalNrOfBranchOffices"
                          helpTextVariant="info"
                        />
                      </div>
                      <div class="col-xl-2 text-right">
                        <label class="pt-4 pb-4"
                          >CHF
                          {{ formatFloat(getTotalBranchOfficesPrice) }}</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-5">
                        <label
                          class="pt-4 pb-4"
                          v-b-tooltip.hover.top="
                            'Bitte beachten Sie, dass 1 BKP Nummer bereits im Basispreis enthalten ist.'
                          "
                          >Zusätzliche BKP hinzufügen CHF
                          {{ formatFloat(getAdditionalBKPPrice) }}</label
                        >
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group">
                          <b-form-radio-group
                            class="pt-4 pb-4"
                            v-model="
                              subscriptionOptions.has_additional_bkp_added
                            "
                            :options="checkboxOptions"
                          ></b-form-radio-group>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <ValidatedNumberInput
                          v-model="
                            subscriptionOptions.nr_of_additional_bkp_added
                          "
                          :v="$v.subscriptionOptions.nr_of_additional_bkp_added"
                          min="0"
                          errorMessage="Der Wert muss mindestens 1 sein."
                          :helpText="'Total = ' + getTotalNrOfBKP"
                          helpTextVariant="info"
                        />
                      </div>
                      <div class="col-xl-2 text-right">
                        <label class="pt-4 pb-4"
                          >CHF
                          {{ formatFloat(getTotalAdditionalBKPPrice) }}</label
                        >
                      </div>
                    </div>
                    <div class="row" v-if="!company.has_portrait">
                      <div class="col-xl-5">
                        <label
                          class="pt-0 pb-4"
                          v-b-tooltip.hover.top="'Comming soon...'"
                          >Firmenprofil hinzufügen CHF
                          {{ formatFloat(getPortraitPrice) }}</label
                        >
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group">
                          <b-form-radio-group
                            class="pt-0 pb-4"
                            v-model="subscriptionOptions.has_portrait_added"
                            :options="checkboxOptions"
                          ></b-form-radio-group>
                        </div>
                      </div>
                      <div class="col-xl-4 text-right">
                        <label class="pt-0 pb-4"
                          >CHF {{ formatFloat(getTotalPortraitPrice) }}</label
                        >
                      </div>
                    </div>
                    <SubscriptionPropertySummaryLine
                      name="Total gewählte Optionen"
                      prefix="CHF"
                      :property="formatFloat(getTotalOptionsPrice)"
                    />
                  </div>
                </div>
                <h4 class="mb-5 font-weight-bold text-dark">
                  Kosten
                </h4>

                <SubscriptionPropertyLine
                  name="Vertragslaufzeit [Jahre]"
                  :property="subscriptionOrder.nr_of_years"
                  postfix="Jahre"
                />
                <SubscriptionPropertyLine
                  name="Listenpreis (Basispreis * Vertragslaufzeit)"
                  prefix="CHF"
                  :property="formatFloat(getTotalListPrice)"
                />

                <div class="pt-5" />

                <SubscriptionPropertyLine
                  :name="
                    'Rabatt aus Abonoment ' +
                      (!subscriptionOrder.allow_options_discount
                        ? '(Abgelaufen am ' +
                          formatDateTime(
                            subscriptionOrder.options_discount_end_date
                          ) +
                          ')'
                        : '')
                  "
                  :property="
                    formatFloat(subscriptionOrder.total_discount_percentage)
                  "
                  postfix="%"
                />

                <SubscriptionPropertyLine
                  name="Gesamtpreis nach Abzug Rabatt"
                  prefix="CHF"
                  :property="formatFloat(getTotalDiscountPrice)"
                />

                <div class="pt-5" />

                <SubscriptionPropertyLine
                  name="Vertragslaufzeit [Tage]"
                  :property="getSubscriptionDurationInDays"
                  postfix="Tage"
                />
                <SubscriptionPropertyLine
                  name="Verbleibende Laufzeit [Tage]"
                  :property="getRemainingSubscriptionDurationInDays"
                  postfix="Tage"
                />
                <SubscriptionPropertyLine
                  name="Verbleibende Laufzeit in %"
                  :property="getRemainingSubscriptionDurationPercentage"
                  postfix="%"
                />

                <div class="pt-5" />

                <SubscriptionPropertySummaryLine
                  name="Total Kosten"
                  prefix="CHF"
                  :property="getRemainingOptionsPrice"
                />
                <!--begin: Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2"></div>
                  <div>
                    <button
                      class="btn btn-danger font-weight-bold text-uppercase px-9 py-4"
                      data-wizard-type="action-submit"
                      :disabled="!(getTotalOptionsPrice > 0)"
                    >
                      Kostenpflichtig bestellen
                    </button>
                  </div>
                </div>
                <!--end: Actions -->
              </div>
            </div>
          </div>
          <!--end::Body-->
        </b-form>
        <!--end::Form-->
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { alertMixin } from "@/mixins/alertMixin";
import { validationMixin } from "vuelidate";
import { numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ValidatedNumberInput from "@/components/ValidatedNumberInput";
import SubscriptionPropertyLine from "@/components/SubscriptionPropertyLine";
import SubscriptionPropertySummaryLine from "@/components/SubscriptionPropertySummaryLine";
import { mapGetters } from "vuex";
import {
  LOAD_SUBSCRIPTION_PRICING,
  LOAD_SUBSCRIPTION_ORDER,
  ADD_SUBSCRIPTION_OPTIONS,
  LOAD_SUBSCRIPTION_OPTIONS
} from "@/store/subscription.module";
import { LOAD_USER_COMPANY } from "@/store/company.module";

function nrOfBranchOfficesValidation(value) {
  return this.validateNrOfBranchOffices(value);
}
function nrOfAdditionalBkpValidation(value) {
  return this.validateNrOfAdditionalBkp(value);
}
export default {
  components: {
    ValidatedNumberInput,
    SubscriptionPropertyLine,
    SubscriptionPropertySummaryLine
  },
  mixins: [validationMixin, utilMixin, alertMixin],
  name: "AddSubscriptionOptions",
  data() {
    return {
      company: {},
      subscriptionOrder: {},
      isLoaded: false,
      subscriptionOptions: {
        has_logo_added: false,
        has_branch_offices_added: false,
        nr_of_branch_offices_added: 1,
        has_additional_bkp_added: false,
        nr_of_additional_bkp_added: 1,
        has_portrait_added: false
      },
      checkboxOptions: [
        { text: "Nein", value: false },
        { text: "Ja", value: true }
      ]
    };
  },
  validations: {
    subscriptionOptions: {
      nr_of_branch_offices_added: {
        nrOfBranchOfficesValidation,
        numeric
      },
      nr_of_additional_bkp_added: {
        nrOfAdditionalBkpValidation,
        numeric
      }
    }
  },
  mounted() {
    this.loadSubscription();
  },
  methods: {
    async loadSubscription() {
      // Load first the pricing before the company is loaded
      await Promise.all([
        this.$store.dispatch(LOAD_SUBSCRIPTION_PRICING),
        this.$store.dispatch(LOAD_SUBSCRIPTION_ORDER),
        this.$store.dispatch(LOAD_USER_COMPANY),
        this.$store.dispatch(LOAD_SUBSCRIPTION_OPTIONS)
      ]).then(() => {
        this.subscriptionOrder = this.getSubscriptionOrder;
        this.company = this.getUserCompany;
        this.subscriptionOptions = this.getSubscriptionOptions;
      });
      this.isLoaded = true;
    },
    save() {
      this.$v.subscriptionOptions.$touch();
      if (this.$v.subscriptionOptions.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      Swal.fire({
        title: "",
        text: "Bitte bestätigen Sie ihre Bestellung.",
        icon: "info",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger"
        },
        confirmButtonText: "Bestellen!",
        cancelButtonText: "Abbrechen",
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(ADD_SUBSCRIPTION_OPTIONS, this.subscriptionOptions)
            .then(() => {
              this.showNotification();
            });
          // .catch(error => {
          //   console.error(error);
          // });
        }
      });
    },
    showNotification() {
      Swal.fire({
        title: "",
        text:
          "Die Optionen wurde erfolgreich hinzugefügt. Sie können Ihre Daten jetzt bearbeiten!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-secondary"
        },
        willClose: () => {
          this.$router.push({ name: "dashboard" });
        }
      });
    },
    validateNrOfBranchOffices(value) {
      if (this.subscriptionOrder.has_branch_offices) {
        return value > 0;
      }
      return true;
    },
    validateNrOfAdditionalBkp(value) {
      if (this.subscriptionOrder.has_additional_bkp) {
        return value > 0;
      }
      return true;
    }
  },
  computed: {
    ...mapGetters([
      "getSubscriptionPricing",
      "getSubscriptionOrder",
      "getSubscriptionOptions",
      "getUserCompany"
    ]),
    getSubscriptionDurationInDays() {
      if (this.subscriptionOrder.total_subscription_duration != null) {
        return this.subscriptionOrder.total_subscription_duration;
      }
      return 0;
    },
    getRemainingSubscriptionDurationInDays() {
      if (this.subscriptionOrder.remaining_subscription_duration != null) {
        return this.subscriptionOrder.remaining_subscription_duration;
      }
      return 0;
    },
    getRemainingSubscriptionDurationPercentage() {
      let days_remaining = this.getRemainingSubscriptionDurationInDays;
      let days_subscription = this.getSubscriptionDurationInDays;
      return ((days_remaining / days_subscription) * 100).toFixed(4);
    },
    getLogoPrice() {
      if (this.getSubscriptionPricing.logo != null) {
        return this.getSubscriptionPricing.logo;
      }
      return 0;
    },
    getBranchOfficesPrice() {
      if (this.getSubscriptionPricing.branch_office != null) {
        return this.getSubscriptionPricing.branch_office;
      }
      return 0;
    },
    getAdditionalBKPPrice() {
      if (this.getSubscriptionPricing.additional_bkp != null) {
        return this.getSubscriptionPricing.additional_bkp;
      }
      return 0;
    },
    getPortraitPrice() {
      if (this.getSubscriptionPricing.portrait != null) {
        return this.getSubscriptionPricing.portrait;
      }
      return 0;
    },
    getTotalLogoPrice() {
      if (this.subscriptionOptions.has_logo_added) {
        return this.getSubscriptionPricing.logo;
      }
      return 0;
    },
    getTotalNrOfBranchOffices() {
      if (this.subscriptionOptions.has_branch_offices_added) {
        return (
          parseInt(this.company.nr_of_branch_offices) +
          parseInt(this.subscriptionOptions.nr_of_branch_offices_added)
        );
      }
      return parseInt(this.company.nr_of_branch_offices);
    },
    getTotalBranchOfficesPrice() {
      if (this.subscriptionOptions.has_branch_offices_added) {
        return (
          parseInt(this.subscriptionOptions.nr_of_branch_offices_added) *
          this.getSubscriptionPricing.branch_office
        );
      }
      return 0;
    },
    getNrOfBKPs() {
      return parseInt(this.company.nr_of_additional_bkp) + 1;
    },
    getTotalNrOfBKP() {
      if (this.subscriptionOptions.has_additional_bkp_added) {
        return (
          parseInt(this.company.nr_of_bkp_numbers) +
          parseInt(this.subscriptionOptions.nr_of_additional_bkp_added)
        );
      }
      return parseInt(this.company.nr_of_bkp_numbers);
    },
    getTotalAdditionalBKPPrice() {
      if (this.subscriptionOptions.has_additional_bkp_added) {
        return (
          parseInt(this.subscriptionOptions.nr_of_additional_bkp_added) *
          this.getSubscriptionPricing.additional_bkp
        );
      }
      return 0;
    },
    getTotalPortraitPrice() {
      if (this.subscriptionOptions.has_portrait_added) {
        return this.getSubscriptionPricing.portrait;
      }
      return 0;
    },
    getTotalOptionsPrice() {
      let total = this.getTotalLogoPrice;
      total += this.getTotalBranchOfficesPrice;
      total += this.getTotalAdditionalBKPPrice;
      total += this.getTotalPortraitPrice;
      return total;
    },
    getTotalListPrice() {
      return (
        this.getTotalOptionsPrice * parseInt(this.subscriptionOrder.nr_of_years)
      );
    },
    getTotalDiscountPrice() {
      if (this.subscriptionOrder.allow_options_discount) {
        return (
          (this.getTotalListPrice / 100) *
          (100 - parseFloat(this.subscriptionOrder.total_discount_percentage))
        );
      }
      return this.getTotalListPrice;
    },
    getRemainingOptionsPrice() {
      return (
        (this.getTotalDiscountPrice *
          this.getRemainingSubscriptionDurationPercentage) /
        100
      ).toFixed(2);
    }
  }
};
</script>
